export function restoreSettings() {
  let settings = null;

  try {
    const storedData = localStorage.getItem('settings');

    if (storedData) {
      settings = JSON.parse(storedData);
    }
  } catch (err) {
    // If stored data is not a strigified JSON this might fail,
    // that's why we catch the error
  }

  return settings;
}

export function storeSettings(settings) {
  localStorage.setItem('settings', JSON.stringify(settings));
}

export const isEcosystem = (accountName) => accountName !== 'Goodsted';
export const isAster = (accountName) =>
  accountName === 'AsterVIP' || accountName === 'Aster Social Impact Ecosystem';
export const isATPI = (accountName) =>
  accountName === 'ATPI' ||
  accountName === 'ATPIn Kind' ||
  accountName === 'ATPI Inspires';
export const isKokoro = (accountName) =>
  accountName === 'Kokoro' || accountName === 'Collective Kiosk';
export const isOrbitGroup = (accountName) => accountName === 'MyVolunteering';
