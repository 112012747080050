import { gql } from 'apollo-boost';

export const ALL_DONATION_TYPES = gql`
  {
    allDonationTypes {
      id
      name
      note
    }
  }
`;

export const GET_DONATION = gql`
  query Donation($id: ID!) {
    Donation(id: $id) {
      id
      donorName
      dateOfPledge
      pledgeAmount
      daysPledged
      recognisedAmount
      whoEnteredTheDonation
      type {
        id
        name
      }
    }
  }
`;

export const GET_DONATION_TYPE = gql`
  query DonationType($id: ID!) {
    DonationType(id: $id) {
      id
      name
      note
    }
  }
`;

export const ALL_DONATIONS = gql`
  {
    allDonations(orderBy: createdAt_DESC) {
      id
      donorName
      dateOfPledge
      pledgeAmount
      daysPledged
      recognisedAmount
      whoEnteredTheDonation
      createdAt
      type {
        id
        name
      }
    }
  }
`;

export const CREATE_DONATION = gql`
  mutation createDonation(
    $donorName: String!
    $dateOfPledge: DateTime!
    $pledgeAmount: Float
    $recognisedAmount: Float
    $typeId: ID!
    $daysPledged: Int
    $whoEnteredTheDonation: String
  ) {
    createDonation(
      donorName: $donorName
      dateOfPledge: $dateOfPledge
      pledgeAmount: $pledgeAmount
      recognisedAmount: $recognisedAmount
      typeId: $typeId
      daysPledged: $daysPledged
      whoEnteredTheDonation: $whoEnteredTheDonation
    ) {
      id
      donorName
      dateOfPledge
      pledgeAmount
      daysPledged
      recognisedAmount
      whoEnteredTheDonation
      type {
        id
      }
    }
  }
`;

export const CREATE_DONATION_TYPE = gql`
  mutation createDonationType($name: String!, $note: String!) {
    createDonationType(name: $name, note: $note) {
      id
      name
      note
    }
  }
`;
export const UPDATE_DONATION_TYPE = gql`
  mutation updateDonationType($id: ID!, $name: String!, $note: String!) {
    updateDonationType(id: $id, name: $name, note: $note) {
      id
      name
      note
    }
  }
`;

export const UPDATE_DONATION = gql`
  mutation updateDonation(
    $id: ID!
    $donorName: String!
    $dateOfPledge: DateTime!
    $pledgeAmount: Float
    $recognisedAmount: Float
    $typeId: ID!
    $daysPledged: Int
    $whoEnteredTheDonation: String
  ) {
    updateDonation(
      id: $id
      donorName: $donorName
      dateOfPledge: $dateOfPledge
      pledgeAmount: $pledgeAmount
      recognisedAmount: $recognisedAmount
      typeId: $typeId
      daysPledged: $daysPledged
      whoEnteredTheDonation: $whoEnteredTheDonation
    ) {
      id
      donorName
      dateOfPledge
      pledgeAmount
      daysPledged
      recognisedAmount
      whoEnteredTheDonation
      type {
        id
        name
      }
    }
  }
`;

export const DELETE_DONATION = gql`
  mutation deleteDonation($id: ID!) {
    deleteDonation(id: $id) {
      id
    }
  }
`;

export const ALL_OPPORTUNITIES = gql`
  query allProjects(
    $first: Int
    $skip: Int
    $orderBy: ProjectOrderBy
    $filter: ProjectFilter
  ) {
    allProjects(
      first: $first
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      name
      image
      type
      profileCompletion
      createdAt
      updatedAt
      start
      end
      isVerified
      isFeatured
      isDateFlexible
      status
      address
      mainType {
        id
        name
      }
      challengeType {
        id
        name
      }
      typeOfChallenge {
        id
        name
      }
      community {
        id
        name
      }
      host {
        id
        firstName
        lastName
        email
      }
    }
    _allProjectsMeta(filter: $filter) {
      count
    }
  }
`;

export const GET_USER = gql`
  query User($id: ID) {
    User(id: $id) {
      id
      firstName
      lastName
      profileImage
      email
      recommendation
      isActive
      reasonForEngaging
      accountActivated
      subTypes {
        id
        name
      }
      profileCompletion
      following {
        id
        community {
          id
          name
        }
      }
      favorites {
        id
        project {
          id
          name
        }
      }
      updatedAt
      phoneNumber
      introduction
      birthDate
      memberType {
        id
        name
      }
      isVisible
      role
      address
      latitude
      longitude
      countryName
      languages {
        id
        name
      }
      categories {
        id
        label
      }
      skills {
        id
        name
      }
      logs(orderBy: createdAt_DESC, first: 1) {
        id
        createdAt
      }
    }
  }
`;

export const GROUP_DETAIL_ALL_USERS = gql`
  query allUsers(
    $communityId: ID
    $first: Int
    $skip: Int
    $filter: UserFilter
  ) {
    allUsers(filter: $filter, first: $first, skip: $skip) {
      id
      firstName
      lastName
      profileImage
      email
      communities(filter: { community: { id: $communityId } }) {
        id
        role
        affiliatedCommunity {
          id
          name
          profileImage
        }
      }
      logs(orderBy: createdAt_DESC, first: 1) {
        id
        createdAt
      }
      isActive
      accountActivated
      profileCompletion
      updatedAt
    }
    _allUsersMeta(filter: $filter) {
      count
    }
  }
`;

export const PARTICIPANT_DETAIL_ALL_USERS = gql`
  query allUsers($first: Int, $skip: Int, $filter: UserFilter) {
    allUsers(filter: $filter, first: $first, skip: $skip) {
      id
      firstName
      lastName
      profileImage
      email

      isActive
      accountActivated
      profileCompletion
      updatedAt
    }
    _allUsersMeta(filter: $filter) {
      count
    }
  }
`;

export const ASSIGN_USERS_TO_OPPORTUNITY = gql`
  mutation assignUsersToOpportunity($userIds: [String], $projectId: String) {
    assignUsersToOpportunity(
      userIds: $userIds
      projectId: $projectId
      status: Confirmed
    ) {
      id
    }
  }
`;

export const ALL_USERS = gql`
  query allUsers($communityId: ID) {
    allUsers(
      filter: {
        isActive: true
        communities_some: { community: { id: $communityId } }
      }
    ) {
      id
      firstName
      lastName
      profileImage
      email
      communities(filter: { community: { id: $communityId } }) {
        id
        role
        affiliatedCommunity {
          id
          name
          profileImage
        }
      }
      logs(orderBy: createdAt_DESC, first: 1) {
        id
        createdAt
      }
      isActive
      accountActivated
      profileCompletion
      updatedAt
    }
  }
`;

export const ALL_ALL_USERS = gql`
  query allUsers(
    $first: Int
    $skip: Int
    $orderBy: UserOrderBy
    $filter: UserFilter
  ) {
    allUsers(
      first: $first
      skip: $skip
      orderBy: $orderBy
      filter: $filter
      ignoreDefaultFilters: true
    ) {
      id
      firstName
      lastName
      profileImage
      email
      recommendation
      memberType {
        id
        name
      }
      profession {
        id
        name
      }
      manualVolunteer(filter: { status: Confirmed }) {
        id
        offerMinutes
        offerTime
      }
      isActive
      isVisible
      accountActivated
      profileCompletion
      updatedAt
      createdAt
      address
      _matchedSupportsMeta: _supportsMeta(filter: { match: { id_not: null } }) {
        count
      }
      _matchesMeta {
        count
      }
      logs(orderBy: createdAt_DESC, first: 1) {
        id
        createdAt
      }

      firstCommunity(
        filter: {
          community: {
            newType_some: { name_not_in: ["Directorate", "DirectorateTeam"] }
          }
        }
      ) {
        id
        community {
          id
          name
        }
      }
      communities(
        filter: {
          community: {
            newType_some: { name_in: ["Directorate", "DirectorateTeam"] }
          }
        }
      ) {
        id
        community {
          id
          name
          communities {
            id
            community {
              id
              name
            }
          }
        }
      }
    }
    _allUsersMeta(filter: $filter, ignoreDefaultFilters: true) {
      count
    }
  }
`;

export const ALL_TEAMS_META = gql`
  query _allCommunitiesMeta($filter: CommunityFilter) {
    _allCommunitiesMeta(filter: $filter) {
      count
    }
  }
`;

export const ALL_GROUPS = gql`
  query allCommunities(
    $first: Int
    $skip: Int
    $orderBy: CommunityOrderBy
    $filter: CommunityFilter
    $communityId: ID
  ) {
    allCommunities(
      first: $first
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      profileImage
      name
      isActive
      isFeatured
      isVerified
      isArchived
      members(filter: { role: Admin }) {
        id
        user {
          id
          firstName
          lastName
          profileImage
          email
          isActive
          role
        }
      }
      address
      profileCompletion
      type
      updatedAt
      website
      wantHelp
      wantSupport
      categories {
        id
        label
      }
      industry {
        id
        name
      }
      newType {
        id
        name
        category {
          id
          name
        }
      }
      communities(filter: { community: { id: $communityId } }) {
        id
        role
      }
    }
    _allCommunitiesMeta(filter: $filter) {
      count
    }
  }
`;

export const ALL_DIRECTORATES = gql`
  {
    allCommunities(
      filter: { newType_some: { name: "Directorate" } }
      orderBy: updatedAt_DESC
    ) {
      id
      profileImage
      name
      address
      profileCompletion
      type
    }
  }
`;

export const ALL_CHALLENGES = gql`
  query allProjects(
    $first: Int
    $skip: Int
    $orderBy: ProjectOrderBy
    $filter: ProjectFilter
  ) {
    allProjects(
      first: $first
      skip: $skip
      orderBy: $orderBy
      filter: $filter
      ignoreDefaultFilters: true
    ) {
      id
      name
      image
      type
      profileCompletion
      createdAt
      updatedAt
      start
      end
      isVerified
      isFeatured
      isDateFlexible
      status
      address
      mainType {
        id
        name
      }
      challengeType {
        id
        name
      }
      typeOfChallenge {
        id
        name
      }
      community {
        id
        name
      }
      host {
        id
        firstName
        lastName
        email
      }
      categories {
        id
        name: label
      }
      neededVolunteer
      placesTaken
    }
    _allProjectsMeta(filter: $filter, ignoreDefaultFilters: true) {
      count
    }
  }
`;

export const ALL_MESSAGES = gql`
  query allMessages {
    allMessages(
      filter: {
        OR: [{ community: { id_not: null } }, { project: { id_not: null } }]
      }
    ) {
      id
      message
      createdAt
      project {
        id
        name
        typeOfChallenge {
          id
          name
        }
        community {
          id
          name
        }
      }
      community {
        id
        name
      }
      sender {
        id
        firstName
        lastName
        profileImage
      }
      conversation {
        id
        users {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const USER_SUPPORTS = gql`
  query allSupports($filter: SupportFilter) {
    allSupports(filter: $filter) {
      id
      createdAt
      status
      isDelivered
      request {
        id
        name
        project {
          id
          name
        }
      }
      match {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
      user {
        id
        firstName
        lastName
        email
      }
      community {
        id
        name
      }
    }
  }
`;

export const PROJECT_ENGAGEMENTS = gql`
  query allManualVolunteers($filter: ManualVolunteerFilter) {
    allManualVolunteers(filter: $filter) {
      id
      createdAt
      updatedAt
      volunteerType
      isVerified
      status
      offerName {
        id
        name
      }
      userEmail
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const GET_CHALLENGE = gql`
  query Project($id: ID!) {
    Project(id: $id) {
      id
      name
      description
      about
      plan
      why
      website
      how
      problem
      need
      start
      end
      type
      address
      country
      image
      deliver
      change
      impact
      hours
      status
      neededVolunteer
      studentAvailable
      expiryDate
      isLocationFlexible
      isFeatured
      isVerified
      onlineSession
      manualAddress
      skillLevels {
        id
        name
      }
      skills {
        id
        name
      }
      profileCompletion
      createdAt
      updatedAt
      challengeLength
      recurringEvery
      recurringFor
      recurringForOptions
      riskAssessmentForm
      expectedTimeForSupport
      isActive
      isArchived
      isDateFlexible
      isLocationRemote
      categories {
        id
        label
      }
      updates {
        id
        createdAt
        details
        images
        documents
        type
        user {
          id
          firstName
          lastName
        }
        impactGoals {
          id
          label
        }
      }
      typeOfChallenge {
        id
        name
      }
      roadmapSteps {
        id
        name
      }
      mainType {
        id
        name
      }
      requests {
        id
        name
        type
        totalNeeded
        spaceSize
        description
        experience
        requiredExperience
        skills {
          id
          name
        }
      }
      challengeType {
        id
        name
      }
      community {
        id
        name
        address
      }
      host {
        id
        firstName
        lastName
        email
      }
      skillLevels {
        id
        name
      }
    }
  }
`;

export const GET_GROUP = gql`
  query Community($id: ID!) {
    Community(id: $id) {
      id
      isActive
      isFeatured
      isArchived
      name
      mission
      profileImage
      bannerImage
      address
      latitude
      recommendation
      industry {
        id
        name
      }
      longitude
      country
      profileCompletion
      type
      website
      wantHelp
      wantSupport
      communities {
        id
        community {
          id
          name
        }
      }
      newType {
        id
        name
        category {
          id
          name
        }
      }
      skills {
        id
        name
      }
      impactGoals {
        id
        label
      }
      neededSkills {
        id
        name
      }
      neededSpaces {
        id
        name
      }
      roadmapSteps {
        id
        name
      }
      categories {
        id
        label
      }
    }
  }
`;

export const GET_GROUP_MEMBER = gql`
  query CommunityUser($id: ID!) {
    CommunityUser(id: $id) {
      id
      role
      status
      affiliatedCommunity {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
      community {
        id
        name
        newType {
          id
          name
          category {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_UPDATE = gql`
  query Update($id: ID!) {
    Update(id: $id) {
      id
      createdAt
      postedAt
      details
      images
      documents
      type
      likes {
        id
      }
      user {
        id
        firstName
        lastName
        email
      }
      impactGoals {
        id
        label
      }
    }
  }
`;

export const AUTHENTICATE_USER = gql`
  mutation authenticateUser($email: String!, $password: String!) {
    authenticateUser(email: $email, password: $password) {
      id
      token
      firstName
      lastName
      email
      profileImage
      role
    }
  }
`;

export const ALL_REQUESTS = gql`
  query allRequests($filter: RequestFilter) {
    allRequests(filter: $filter) {
      id
      name
      totalNeeded
      amount
      requiredExperience
      experience
      _supportsMeta {
        count
      }
      project {
        id
        name
      }
      skills {
        id
        name
      }
    }
  }
`;

export const ALL_SKILLS_WITH_RELATIONS = gql`
  query allSkillsWithRelations {
    allSkills {
      id
      name
      categories {
        id
        name
      }
      members: _usersMeta {
        count
      }
      challenges: _requestsMeta(
        filter: { project: { typeOfChallenge: { name: "Challenge" } } }
      ) {
        count
      }
      challenges: _requestsMeta(
        filter: { project: { typeOfChallenge: { name: "Challenge" } } }
      ) {
        count
      }
      requests: _requestsMeta {
        count
      }
      offers: _supportsMeta {
        count
      }
    }
  }
`;

export const ALL_SKILLS = gql`
  query allSkills {
    allSkills {
      id
      name
    }
  }
`;

export const ALL_WRITERS = gql`
  query allWriters {
    allWriters: allUsers(filter: { role_in: [WRITER, ADMIN] }) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const ALL_USERS_SEARCH = gql`
  query allUsers($input: String) {
    allUsers(
      first: 10
      orderBy: firstName_ASC
      filter: {
        isActive: true
        OR: [{ firstName_contains: $input }, { lastName_contains: $input }]
      }
    ) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const ALL_SUB_TYPES = gql`
  query allSubTypes {
    allSubTypes {
      id
      name
    }
  }
`;

export const ALL_GROUPS_SEARCH = gql`
  query allCommunities($filter: CommunityFilter) {
    allCommunities(first: 10, orderBy: name_ASC, filter: $filter) {
      id
      name
    }
  }
`;

export const ALL_UPDATES = gql`
  query allUpdates(
    $first: Int
    $skip: Int
    $orderBy: UpdateOrderBy
    $filter: UpdateFilter
  ) {
    allUpdates(
      first: $first
      skip: $skip
      orderBy: $orderBy
      filter: $filter
      ignoreDefaultFilters: true
    ) {
      id
      createdAt
      details
      images
      documents
      type
      isVerified
      user {
        id
        firstName
        lastName
      }
      impactGoals {
        id
        label
      }
    }
    _allUpdatesMeta(filter: $filter) {
      count
    }
  }
`;

export const ALL_CONFIGURATIONS = gql`
  query allConfigurations($filter: ConfigurationFilter) {
    allConfigurations(filter: $filter) {
      id
      createdAt
      updatedAt
      name
      value
    }
  }
`;

export const DELETE_CONFIGURATION = gql`
  mutation deleteConfiguration($id: ID!) {
    deleteConfiguration(id: $id) {
      id
    }
  }
`;

export const UPDATE_CONFIGURATION = gql`
  mutation updateConfiguration($id: ID!, $value: String!) {
    updateConfiguration(id: $id, value: $value) {
      id
      value
    }
  }
`;

export const ALL_CAUSES = gql`
  query allCategories($filter: CategoryFilter) {
    allCategories(filter: $filter) {
      id
      label
      order
      sustainabilityPillars {
        id
        name
      }
      impactGoals {
        id
        label
      }
      partners: _usersMeta(
        filter: { memberType: { name: "Community Partner" } }
      ) {
        count
      }
      challenges: _projectsMeta(
        filter: { typeOfChallenge: { name: "Challenge" } }
      ) {
        count
      }
      challenges: _projectsMeta(
        filter: { typeOfChallenge: { name: "Challenge" } }
      ) {
        count
      }
    }
  }
`;

export const ALL_CAUSES_SIMPLIFIED = gql`
  query allCategories($filter: CategoryFilter) {
    allCategories(filter: $filter) {
      id
      label
      order
    }
  }
`;

export const GET_CAUSE = gql`
  query Category($id: ID!) {
    Category(id: $id) {
      id
      label
      order
      sustainabilityPillars {
        id
        name
      }
      impactGoals {
        id
        label
      }
      partners: _usersMeta(
        filter: { memberType: { name: "Community Partner" } }
      ) {
        count
      }
      challenges: _projectsMeta(
        filter: { typeOfChallenge: { name: "Challenge" } }
      ) {
        count
      }
      challenges: _projectsMeta(
        filter: { typeOfChallenge: { name: "Challenge" } }
      ) {
        count
      }
    }
  }
`;

export const UPDATE_CAUSE = gql`
  mutation updateCategory($id: ID!, $order: Int) {
    updateCategory(id: $id, order: $order) {
      id
      order
    }
  }
`;

export const ALL_CATEGORIES = gql`
  query allCategories {
    allCategories {
      id
      label
    }
  }
`;

export const ALL_INDUSTRIES = gql`
  query allIndustries {
    allIndustries {
      id
      name
    }
  }
`;

export const ALL_NEW_COMMUNITY_TYPES = gql`
  query allNewCommunityTypes {
    allNewCommunityTypes {
      id
      name
    }
  }
`;

export const ALL_MEMBER_TYPES = gql`
  {
    allMemberTypes {
      id
      name
    }
  }
`;

export const ALL_SPACES = gql`
  {
    allSpaces {
      id
      name
    }
  }
`;

export const ALL_ROLES = gql`
  {
    __type(name: "UserRole") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const ALL_STATUSES = gql`
  query enumCompletionStatus {
    __type(name: "CompletionStatus") {
      name
      enumValues {
        name
      }
    }
  }
`;
export const ALL_ORGANIZATION_ROLES = gql`
  {
    __type(name: "OrganizationRole") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const ACTIVATE_USER = gql`
  mutation activateUser($id: ID!, $isActive: Boolean) {
    customUpdateUser(id: $id, isActive: $isActive) {
      id
      isActive
    }
  }
`;

export const ACTIVATE_GROUP = gql`
  mutation activateGroup($id: ID!, $isVerified: Boolean) {
    customUpdateCommunity(id: $id, isVerified: $isVerified) {
      id
      isVerified
    }
  }
`;

export const VERIFY_PROJECT = gql`
  mutation verifyProject($id: ID!, $isVerified: Boolean) {
    verifyProject(id: $id, isVerified: $isVerified) {
      id
      isVerified
    }
  }
`;

export const ACTIVATE_UPDATE = gql`
  mutation activateUpdate($id: ID!, $isVerified: Boolean) {
    updateUpdate(id: $id, isVerified: $isVerified) {
      id
      isVerified
    }
  }
`;

export const DELETE_CHALLENGE = gql`
  mutation deleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_COMMUNITY_USER = gql`
  mutation deleteCommunityUser($id: ID!) {
    deleteCommunityUser(id: $id) {
      id
    }
  }
`;

export const ALL_CHALLENGE_TYPES = gql`
  query allTypeOfChallenges {
    allChallengeTypes {
      id
      name
      description
    }
  }
`;

export const ALL_TYPE_OF_CHALLENGES = gql`
  query allTypeOfChallengesWithMainTypes {
    allTypeOfChallenges {
      id
      name
      mainType {
        id
      }
    }
  }
`;

export const ALL_MAIN_TYPES = gql`
  query allMainTypes {
    allMainTypes {
      id
      name
    }
  }
`;

export const ALL_MAIN_TYPES_WITH_TYPE_OF_CHALLENGES = gql`
  query allMainTypesWithTypeOfChallenges {
    allMainTypes {
      id
      name
      typeOfChallenges {
        id
        name
      }
    }
  }
`;

export const ALL_SKILL_LEVELS = gql`
  query allSkillLevels {
    allSkillLevels {
      id
      name
    }
  }
`;

export const CREATE_CHALLENGE = gql`
  mutation createProject(
    $name: String!
    $description: String!
    $customCreatedAt: DateTime!
    $image: String
    $communityId: ID!
    $problem: String
    $need: String
    $recurringEvery: String
    $recurringFor: Int
    $recurringForOptions: String
    $start: DateTime
    $end: DateTime
    $type: ProjectType
    $challengeLength: String
    $isDateFlexible: Boolean
    $isLocationRemote: Boolean
    $hostId: ID
    $requests: [ProjectrequestsRequest!]
    $roadmapSteps: [ProjectroadmapStepsRoadmapStep!]
    $challengeTypeIds: [ID!]
    $address: String
    $latitude: Float
    $longitude: Float
    $mainTypeId: ID
    $categoriesIds: [ID!]
  ) {
    createProject(
      name: $name
      description: $description
      customCreatedAt: $customCreatedAt
      image: $image
      communityId: $communityId
      problem: $problem
      need: $need
      recurringEvery: $recurringEvery
      recurringFor: $recurringFor
      recurringForOptions: $recurringForOptions
      start: $start
      end: $end
      type: $type
      categoriesIds: $categoriesIds
      challengeLength: $challengeLength
      isDateFlexible: $isDateFlexible
      isLocationRemote: $isLocationRemote
      hostId: $hostId
      requests: $requests
      roadmapSteps: $roadmapSteps
      challengeTypeIds: $challengeTypeIds
      address: $address
      latitude: $latitude
      longitude: $longitude
      mainTypeId: $mainTypeId
    ) {
      id
      name
      community {
        id
      }
    }
  }
`;

export const UPDATE_CHALLENGE = gql`
  mutation updateProject(
    $challengeLength: String
    $challengeTypeIds: [ID!]
    $communityId: ID
    $description: String
    $end: DateTime
    $id: ID!
    $image: String
    $isActive: Boolean
    $isFeatured: Boolean
    $isArchived: Boolean
    $isDateFlexible: Boolean
    $isLocationRemote: Boolean
    $name: String
    $need: String
    $problem: String
    $recurringEvery: String
    $recurringFor: Int
    $recurringForOptions: String
    $start: DateTime
    $type: ProjectType
    $typeOfChallengeId: ID
    $hostId: ID
    $address: String
    $country: String
    $latitude: Float
    $longitude: Float
    $mainTypeId: ID
    $categoriesIds: [ID!]
    $expectedTimeForSupport: Int
    $expiryDate: DateTime
    $skillLevelsIds: [ID!]
    $studentAvailable: Boolean
    $status: CompletionStatus
    $website: String
  ) {
    updateProject(
      challengeLength: $challengeLength
      challengeTypeIds: $challengeTypeIds
      communityId: $communityId
      description: $description
      end: $end
      id: $id
      image: $image
      isActive: $isActive
      isFeatured: $isFeatured
      isArchived: $isArchived
      isDateFlexible: $isDateFlexible
      isLocationRemote: $isLocationRemote
      name: $name
      need: $need
      problem: $problem
      recurringEvery: $recurringEvery
      recurringFor: $recurringFor
      recurringForOptions: $recurringForOptions
      start: $start
      type: $type
      typeOfChallengeId: $typeOfChallengeId
      hostId: $hostId
      address: $address
      country: $country
      latitude: $latitude
      longitude: $longitude
      mainTypeId: $mainTypeId
      categoriesIds: $categoriesIds
      expectedTimeForSupport: $expectedTimeForSupport
      expiryDate: $expiryDate
      skillLevelsIds: $skillLevelsIds
      studentAvailable: $studentAvailable
      status: $status
      website: $website
    ) {
      challengeLength
      challengeType {
        id
      }
      community {
        id
      }
      description
      end
      id
      image
      isActive
      isFeatured
      isArchived
      address
      country
      latitude
      longitude
      isDateFlexible
      isLocationRemote
      expectedTimeForSupport
      name
      need
      problem
      recurringEvery
      recurringFor
      recurringForOptions
      start
      type
      website
      typeOfChallenge {
        id
        name
      }
      mainType {
        id
        name
      }
      expiryDate
      skillLevels {
        id
      }
    }
  }
`;

export const DELETE_BLOG = gql`
  mutation deleteBlog($id: ID!) {
    deleteBlog(id: $id) {
      id
    }
  }
`;

export const DELETE_MANUAL_VOLUNTEER = gql`
  mutation deleteManualVolunteer($id: ID!) {
    deleteManualVolunteer(id: $id) {
      id
    }
  }
`;
export const DELETE_UPDATE = gql`
  mutation deleteUpdate($id: ID!) {
    deleteUpdate(id: $id) {
      id
    }
  }
`;
export const DELETE_LIKE = gql`
  mutation deleteLike($id: ID!) {
    deleteLike(id: $id) {
      id
    }
  }
`;

export const UPDATE_REQUEST = gql`
  mutation updateRequest(
    $id: ID!
    $name: String
    $type: RequestType
    $skills: [RequestskillsSkill!]
    $totalNeeded: Int
    $description: String
    $spaceSize: String
    $experience: String
    $skillsIds: [ID!]
  ) {
    updateRequest(
      id: $id
      name: $name
      type: $type
      skills: $skills
      totalNeeded: $totalNeeded
      description: $description
      spaceSize: $spaceSize
      experience: $experience
      skillsIds: $skillsIds
    ) {
      id
      name
      type
      totalNeeded
      description
      spaceSize
      experience
      skills {
        id
        name
      }
    }
  }
`;

export const UPDATE_GOAL = gql`
  mutation updateRoadmapStep($id: ID!, $name: String) {
    updateRoadmapStep(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const CREATE_MANUAL_VOLUNTEER = gql`
  mutation createManualVolunteer(
    $volunteerType: String
    $offerType: String
    $offerTime: String
    $offerMinutes: String
    $challengeName: String
    $do: String
    $challengeDetail: String
    $onlineSession: String
    $startDate: DateTime
    $endDate: DateTime
    $goals: [String!]
    $images: [String!]
    $links: [String!]
    $communityName: String
    $isLocationFlexible: Boolean
    $isLocationRemote: Boolean
    $isDateFlexible: Boolean
    $isVerified: Boolean
    $offerNameId: ID
    $userId: ID
    $userEmail: String
    $cityId: ID
    $representCommunityName: String
    $communityCityId: ID
    $projectTypeId: ID
    $communityId: ID
    $categoriesIds: [ID!]
    $files: [String!]
    $typesIds: [ID!]
    $status: SupportStatus
    $projectId: ID
    $contactName: String
    $contactEmail: String
  ) {
    createManualVolunteer(
      volunteerType: $volunteerType
      offerType: $offerType
      offerTime: $offerTime
      contactName: $contactName
      contactEmail: $contactEmail
      do: $do
      offerMinutes: $offerMinutes
      challengeName: $challengeName
      challengeDetail: $challengeDetail
      onlineSession: $onlineSession
      startDate: $startDate
      endDate: $endDate
      goals: $goals
      images: $images
      projectId: $projectId
      links: $links
      communityName: $communityName
      isLocationFlexible: $isLocationFlexible
      isLocationRemote: $isLocationRemote
      isDateFlexible: $isDateFlexible
      isVerified: $isVerified
      offerNameId: $offerNameId
      userId: $userId
      userEmail: $userEmail
      cityId: $cityId
      representCommunityName: $representCommunityName
      communityCityId: $communityCityId
      projectTypeId: $projectTypeId
      communityId: $communityId
      categoriesIds: $categoriesIds
      files: $files
      typesIds: $typesIds
      status: $status
    ) {
      id
      userEmail
      project {
        id
        problem
        whatsappLink
        riskAssessmentForm
        recurringEvery
        onlineSession
        recurringFor
        recurringForOptions
        address
        pledgeCount
        why
        manualVolunteers {
          id
          createdAt
          do
          offerTime
          userEmail
          user {
            id
            firstName
            lastName
            profileImage
          }
          challengeDetail
          links
          images
        }
        community {
          id
          categories {
            id
            label
          }
        }
        impactGoals {
          id
        }
        challengeLength
        need
        isDateFlexible
        typeOfChallenge {
          id
          name
        }
        challengeType {
          id
          name
        }
        isLocationFlexible
        plan
        isLocationRemote
        type
        length
        roadmapSteps {
          id
          name
          update {
            id
          }
        }
        categories {
          id
          label
          icon
        }
        description
        community {
          id
          name
          slug
          profileImage
          address
        }
        end
        host {
          id
          firstName
          lastName
          profileImage
          introduction
        }
        completedRequests: _requestsMeta(filter: { isComplete: true }) {
          count
        }
        allRequests: _requestsMeta {
          count
        }
        name
        start
        verified
        image
        requests {
          id
          supports {
            id
            status
            isDelivered
          }
          date {
            id
            start
            end
          }
          isComplete
        }
        project(filter: { action: { name: "idea" } }) {
          id
          user {
            id
            firstName
            lastName
            profileImage
          }
        }
      }
    }
  }
`;

export const CREATE_GOAL = gql`
  mutation createRoadmapStep($name: String!, $communityId: ID) {
    createRoadmapStep(name: $name, communityId: $communityId) {
      id
      name
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation createCommunity(
    $name: String!
    $cityId: ID
    $newTypeIds: [ID!]
    $profileImage: String!
    $bannerImage: String
    $wantSupport: Boolean
    $wantHelp: Boolean
    $mission: String
    $size: String
    $categoriesIds: [ID!]
    $communities: [CommunitycommunitiesCommunityUser!]
    $members: [CommunitymembersCommunityUser!]
    $address: String
    $latitude: Float
    $longitude: Float
    $country: String
    $isVerified: Boolean
  ) {
    createCommunity(
      name: $name
      cityId: $cityId
      newTypeIds: $newTypeIds
      profileImage: $profileImage
      bannerImage: $bannerImage
      wantSupport: $wantSupport
      wantHelp: $wantHelp
      mission: $mission
      members: $members
      size: $size
      categoriesIds: $categoriesIds
      communities: $communities
      address: $address
      latitude: $latitude
      longitude: $longitude
      country: $country
      isVerified: $isVerified
    ) {
      id
      name
    }
  }
`;

export const CREATE_COMMUNITY_USER = gql`
  mutation createCommunityUser(
    $communityId: ID!
    $userId: ID
    $memberCommunityId: ID
    $role: OrganizationRole
  ) {
    createCommunityUser(
      userId: $userId
      communityId: $communityId
      memberCommunityId: $memberCommunityId
      role: $role
      status: Confirmed
    ) {
      id
    }
  }
`;

export const UPDATE_UPDATE = gql`
  mutation updateUpdate(
    $id: ID!
    $postedAt: DateTime
    $details: String
    $userId: ID
  ) {
    updateUpdate(
      id: $id
      postedAt: $postedAt
      details: $details
      userId: $userId
    ) {
      id
      postedAt
      details
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateCommunity(
    $bannerImage: String
    $categoriesIds: [ID!]
    $cityId: ID
    $id: ID!
    $isActive: Boolean
    $isFeatured: Boolean
    $isArchived: Boolean
    $mission: String
    $name: String
    $newTypeIds: [ID!]
    $profileImage: String
    $roadmapStepsIds: [ID!]
    $skillsIds: [ID!]
    $neededSkillsIds: [ID!]
    $wantHelp: Boolean
    $wantSupport: Boolean
    $website: String
    $address: String
    $latitude: Float
    $longitude: Float
    $country: String
  ) {
    updateCommunity(
      bannerImage: $bannerImage
      categoriesIds: $categoriesIds
      cityId: $cityId
      id: $id
      isActive: $isActive
      isArchived: $isArchived
      isFeatured: $isFeatured
      mission: $mission
      name: $name
      newTypeIds: $newTypeIds
      profileImage: $profileImage
      roadmapStepsIds: $roadmapStepsIds
      skillsIds: $skillsIds
      neededSkillsIds: $neededSkillsIds
      wantHelp: $wantHelp
      wantSupport: $wantSupport
      website: $website
      address: $address
      latitude: $latitude
      longitude: $longitude
      country: $country
    ) {
      address
      bannerImage
      categories {
        id
      }
      id
      isActive
      isFeatured
      isArchived
      mission
      name
      newType {
        id
      }
      profileImage
      roadmapSteps {
        id
      }
      skills {
        id
      }
      type
      wantHelp
      wantSupport
      website
      country
    }
  }
`;

export const UPDATE_GROUP_MEMBER = gql`
  mutation updateCommunityUser(
    $id: ID!
    $role: OrganizationRole
    $affiliatedCommunityId: ID
  ) {
    updateCommunityUser(
      id: $id
      role: $role
      affiliatedCommunityId: $affiliatedCommunityId
    ) {
      id
      role
      affiliatedCommunity {
        id
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $profileImage: String
    $introduction: String
    $phoneNumber: String
    $birthDate: DateTime
    $languagesIds: [ID!]
    $skillsIds: [ID!]
    $categoriesIds: [ID!]
    $isVisible: Boolean
    $role: UserRole
    $address: String
    $latitude: Float
    $longitude: Float
    $memberTypeId: ID
    $countryName: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      profileImage: $profileImage
      introduction: $introduction
      phoneNumber: $phoneNumber
      birthDate: $birthDate
      languagesIds: $languagesIds
      skillsIds: $skillsIds
      categoriesIds: $categoriesIds
      isVisible: $isVisible
      role: $role
      address: $address
      latitude: $latitude
      longitude: $longitude
      memberTypeId: $memberTypeId
      countryName: $countryName
    ) {
      id
      firstName
      lastName
      email
      profileImage
      introduction
      phoneNumber
      birthDate
      languages {
        id
      }
      memberType {
        id
        name
      }
      skills {
        id
      }
      categories {
        id
      }
      languages {
        id
      }
      isVisible
      role
      address
      latitude
      longitude
      countryName
    }
  }
`;

export const ORGANISATION_TYPES = gql`
  query organisationTypes {
    allNewCommunityTypes {
      id
      name
    }
  }
`;

export const GROUP_NAME = gql`
  query Community($id: ID!) {
    Community(id: $id) {
      id
      name
    }
  }
`;

export const ALL_IMPACT_GOALS = gql`
  query allImpactGoals {
    allImpactGoals {
      id
      label
    }
  }
`;

export const ALL_LANGUAGES = gql`
  query allLanguages {
    allLanguages {
      id
      name
    }
  }
`;

export const ALL_SUPPORTS = gql`
  query allSupports(
    $first: Int
    $skip: Int
    $orderBy: SupportOrderBy
    $filter: SupportFilter
  ) {
    allSupports(
      first: $first
      skip: $skip
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      status
      isDelivered
      createdAt
      community {
        id
        name
      }
      user {
        id
        firstName
        lastName
        email
        memberType {
          id
          name
        }
        communities(
          filter: { community: { newType_some: { name: "DirectorateTeam" } } }
          first: 1
        ) {
          id
          community {
            id
            name
            communities(
              filter: { community: { newType_some: { name: "Directorate" } } }
            ) {
              id
              community {
                id
                name
              }
            }
          }
        }
      }
      messages {
        id
        message
      }
      request {
        id
        name
        project {
          id
          name
          start
          end
          community {
            id
            name
          }
          categories {
            id
            name: label
          }
        }
      }
    }
    _allSupportsMeta(filter: $filter) {
      count
    }
  }
`;

export const GET_SUPPORT = gql`
  query Support($id: ID!) {
    Support(id: $id) {
      id
      status
      createdAt
      details
      createdAt
      status
      experienceComment
      estimatedTime
      isDelivered
      statusUpdatedAt
      review {
        id
        user {
          id
          firstName
          lastName
        }
        rating {
          id
          feedback
          recommendation
          goodstedRating
        }
      }
      match {
        id
        user {
          id
          firstName
          lastName
          email
        }
      }
      user {
        id
        firstName
        lastName
        email
      }
      community {
        id
        name
      }
      request {
        id
        name
      }
      skills {
        id
        name
      }
    }
  }
`;

export const ALL_BLOG_TYPES = gql`
  {
    allBlogTypes {
      id
      name
    }
  }
`;

export const GET_BLOG = gql`
  query Blog($id: ID!) {
    Blog(id: $id) {
      id
      name
      publishedAt
      content
      isFeatured
      isActive
      coverImage
      shortContent
      user {
        id
        firstName
        lastName
      }
      types {
        id
        name
      }
      skills {
        id
        name
      }
    }
  }
`;

export const ALL_BLOGS = gql`
  {
    allBlogs {
      id
      name
      coverImage
      publishedAt
      isFeatured
      types {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const ALL_CONVERSATIONS = gql`
  {
    allConversations {
      id
      community {
        id
      }
      project {
        id
      }
      messages {
        id
        message
        createdAt
        sender {
          id
          firstName
          profileImage
        }
      }
      users {
        id
        firstName
        profileImage
      }
    }
  }
`;

export const CREATE_BLOG = gql`
  mutation createBlog(
    $name: String!
    $content: String
    $isFeatured: Boolean
    $coverImage: String
    $shortContent: String
    $userId: ID
    $typesIds: [ID!]
    $skillsIds: [ID!]
  ) {
    createBlog(
      name: $name
      content: $content
      isFeatured: $isFeatured
      coverImage: $coverImage
      shortContent: $shortContent
      userId: $userId
      typesIds: $typesIds
      skillsIds: $skillsIds
    ) {
      id
      name
    }
  }
`;

export const CREATE_CONFIGURATION = gql`
  mutation createConfiguration($name: String!, $value: String!) {
    createConfiguration(name: $name, value: $value) {
      id
      name
      value
    }
  }
`;

export const UPDATE_BLOG = gql`
  mutation updateBlog(
    $id: ID!
    $name: String
    $content: String
    $isFeatured: Boolean
    $coverImage: String
    $shortContent: String
    $userId: ID
    $typesIds: [ID!]
    $skillsIds: [ID!]
    $publishedAt: DateTime
    $isActive: Boolean
  ) {
    updateBlog(
      id: $id
      name: $name
      content: $content
      isFeatured: $isFeatured
      coverImage: $coverImage
      shortContent: $shortContent
      userId: $userId
      typesIds: $typesIds
      skillsIds: $skillsIds
      publishedAt: $publishedAt
      isActive: $isActive
    ) {
      id
      name
      content
      isFeatured
      coverImage
      shortContent
      publishedAt
      user {
        id
      }
      types {
        id
      }
      skills {
        id
      }
      isActive
    }
  }
`;

export const ALL_UPDATE_ACTIONS = gql`
  query allUpdateActions {
    allUpdateActions {
      id
      name
    }
  }
`;

export const CREATE_UPDATE_MUTATION = gql`
  mutation createUpdate(
    $details: String
    $communityId: ID
    $userId: ID!
    $isPrivate: Boolean
    $type: UpdateType
    $updateCommunityId: ID
    $updateProjectId: ID
    $actionUserId: ID
    $actionId: ID
    $actionManualVolunteerId: ID
  ) {
    createUpdate(
      details: $details
      actionManualVolunteerId: $actionManualVolunteerId
      communityId: $communityId
      updateCommunityId: $updateCommunityId
      userId: $userId
      isPrivate: $isPrivate
      type: $type
      updateProjectId: $updateProjectId
      actionUserId: $actionUserId
      actionId: $actionId
    ) {
      id
      type
      details
      action {
        id
      }
      isPrivate
    }
  }
`;

export const ALL_MANUAL_VOLUNTEERS = gql`
  query allManualVolunteers(
    $skip: Int
    $first: Int
    $orderBy: ManualVolunteerOrderBy
    $filter: ManualVolunteerFilter
  ) {
    allManualVolunteers(
      skip: $skip
      first: $first
      orderBy: $orderBy
      filter: $filter
    ) {
      id
      createdAt
      updatedAt
      volunteerType
      offerType
      offerTime
      offerMinutes
      challengeName
      challengeDetail
      onlineSession
      startDate
      endDate
      goals
      images
      links
      communityName
      isLocationFlexible
      isLocationRemote
      isDateFlexible
      isVerified
      do
      offerName {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
      isAddedOnBehalf
      addingUser {
        id
        firstName
        lastName
      }
      isAutoLogged
      userEmail
      files
      representCommunityName
      categories {
        id
        label
      }
      projectType {
        id
        name
      }
      community {
        id
        name
        profileImage
      }
      affiliatedCommunity {
        id
        name
        profileImage
        newType {
          name
        }
      }
      types {
        id
        name
      }
      project {
        id
        name
        address
      }
      status
      extraHourReason
    }
    _allManualVolunteersMeta(filter: $filter) {
      count
    }
  }
`;

export const MANUAL_VOLUNTEER = gql`
  query ManualVolunteer($id: ID!) {
    ManualVolunteer(id: $id) {
      id
      createdAt
      updatedAt
      volunteerType
      offerType
      offerTime
      offerMinutes
      challengeName
      challengeDetail
      onlineSession
      startDate
      endDate
      goals
      images
      links
      communityName
      isLocationFlexible
      isLocationRemote
      isDateFlexible
      isVerified
      do
      offerName {
        id
        name
      }
      userEmail
      user {
        id
        firstName
        lastName
        email
      }
      files
      representCommunityName
      categories {
        id
        label
      }
      projectType {
        id
        name
      }
      types {
        id
        name
      }
      community {
        id
        name
        profileImage
      }
      project {
        id
        name
      }
      status
    }
  }
`;

export const UPDATE_MANUAL_VOLUNTEER = gql`
  mutation updateManualVolunteer(
    $id: ID!
    $volunteerType: String
    $offerType: String
    $offerTime: String
    $offerMinutes: String
    $challengeName: String
    $do: String
    $challengeDetail: String
    $onlineSession: String
    $startDate: DateTime
    $endDate: DateTime
    $goals: [String!]
    $images: [String!]
    $links: [String!]
    $communityName: String
    $isLocationFlexible: Boolean
    $isLocationRemote: Boolean
    $isDateFlexible: Boolean
    $isVerified: Boolean
    $offerNameId: ID
    $userId: ID
    $userEmail: String
    $cityId: ID
    $representCommunityName: String
    $communityCityId: ID
    $projectTypeId: ID
    $communityId: ID
    $categoriesIds: [ID!]
    $files: [String!]
    $typesIds: [ID!]
    $status: SupportStatus
    $projectId: ID
    $contactName: String
    $contactEmail: String
  ) {
    updateManualVolunteer(
      id: $id
      volunteerType: $volunteerType
      offerType: $offerType
      offerTime: $offerTime
      contactName: $contactName
      contactEmail: $contactEmail
      do: $do
      offerMinutes: $offerMinutes
      challengeName: $challengeName
      challengeDetail: $challengeDetail
      onlineSession: $onlineSession
      startDate: $startDate
      endDate: $endDate
      goals: $goals
      images: $images
      projectId: $projectId
      links: $links
      communityName: $communityName
      isLocationFlexible: $isLocationFlexible
      isLocationRemote: $isLocationRemote
      isDateFlexible: $isDateFlexible
      isVerified: $isVerified
      offerNameId: $offerNameId
      userId: $userId
      userEmail: $userEmail
      cityId: $cityId
      representCommunityName: $representCommunityName
      communityCityId: $communityCityId
      projectTypeId: $projectTypeId
      communityId: $communityId
      categoriesIds: $categoriesIds
      files: $files
      typesIds: $typesIds
      status: $status
    ) {
      id
      createdAt
      updatedAt
      volunteerType
      offerType
      offerTime
      offerMinutes
      challengeName
      challengeDetail
      onlineSession
      startDate
      endDate
      goals
      images
      links
      communityName
      isLocationFlexible
      isLocationRemote
      isDateFlexible
      isVerified
      do
      offerName {
        id
        name
      }
      user {
        id
        firstName
        lastName
      }
      userEmail
      files
      representCommunityName
      categories {
        id
        label
      }
      projectType {
        id
        name
      }
      community {
        id
        name
        profileImage
      }
      affiliatedCommunity {
        id
        name
        profileImage
      }
      types {
        id
        name
      }
      project {
        id
        name
      }
      status
    }
  }
`;

export const CUSTOM_UPDATE_MANUAL_VOLUNTEER = gql`
  mutation customUpdateManualVolunteer($id: ID!, $isVerified: Boolean) {
    customUpdateManualVolunteer(id: $id, isVerified: $isVerified) {
      id
      isVerified
    }
  }
`;

export const ALL_COMMUNITY_TYPE_CATEGORIES = gql`
  query allCommunityTypeCategories {
    allCommunityTypeCategories {
      id
      name
      image
      types {
        id
        name
      }
    }
  }
`;

export const USER_MANUAL_VOLUNTEERS = gql`
  query allManualVolunteers($filter: ManualVolunteerFilter) {
    allManualVolunteers(filter: $filter) {
      id
      createdAt
      updatedAt
      volunteerType
      offerType
      offerTime
      challengeName
      challengeDetail
      onlineSession
      startDate
      endDate
      goals
      images
      links
      communityName
      isLocationFlexible
      isLocationRemote
      isDateFlexible
      isVerified
      id
      offerName {
        id
        name
      }
      userEmail
      user {
        id
        firstName
        lastName
      }
      files
      representCommunityName
      categories {
        id
        label
      }
      projectType {
        id
        name
      }
      types {
        id
        name
      }
      project {
        id
        name
      }
      community {
        id
        name
        profileImage
      }
      status
    }
  }
`;

// export const RECOMMENDED_CHALLENGES_QUERY = gql`
// # bu query recommended challenge veriyor bize bu queryden challenge gelecek userların sayısı lazım.
// # recommended challengeları olan userlar yani
// query User($id: ID) {
//     User(id: $id) {
//       id
//       skills(filter: { requests_some: { id_not: null } }) {
//         id
//         requests(
//           filter: {
//             AND: [
//               { supports_every: { status: Confirmed, user: { id_not: $id } } }
//               {
//                 project: {
//                   AND: [{ status_not: Expired }, { status_not: Completed }]
//                   host: { id_not: $id }
//                   isActive: true
//                 }
//               }
//             ]
//           }
//         ) {
//           id
//           project {
//             id
//             status
//             community {
//               id
//               name
//               slug
//               profileImage
//             }
//             categories {
//               id
//               label
//             }
//             requests {
//               id
//               name
//               supports {
//                 id
//                 status
//                 isDelivered
//               }
//               date {
//                 id
//                 start
//                 end
//               }
//               isComplete
//             }
//             isDateFlexible
//             isLocationRemote
//             id
//             image
//             challengeLength
//             length
//             name
//             type
//             verified
//             typeOfChallenge {
//               id
//               name
//             }
//             challengeType {
//               id
//               name
//             }
//             address
//             city {
//               id
//               name
//               county {
//                 id
//                 name
//               }
//             }
//             district {
//               id
//               name
//             }
//             host {
//               id
//               firstName
//               lastName
//               profileImage
//             }
//             completed: _requestsMeta(filter: { supports_some: { status: Confirmed } }) {
//               count
//             }
//             _requestsMeta {
//               count
//             }
//             community {
//               id
//               slug
//               categories {
//                 id
//                 label
//               }
//             }
//             start
//             end
//           }
//         }
//       }
//     }
//   }`;

export const ALL_PRICE_REQUESTS = gql`
  query allPriceRequests {
    allPriceRequests {
      id
      firstName
      lastName
      email
      phoneNumber
      companyName
      numberOfEmployees
      jobTitle
      interestedIn
    }
  }
`;

export const GET_IN_TOUCH_MESSAGES = gql`
  query allMessages {
    allMessages(
      filter: { AND: [{ community: { id_not: null } }, { type: GetInTouch }] }
    ) {
      id
      message
      createdAt
      community {
        id
        name
      }
      resources
      purpose
      affiliation {
        id
        name
        profileImage
      }
      relevantProject {
        id
        name
        image
      }
      sender {
        id
        firstName
        lastName
        profileImage
      }
      conversation {
        id
        users {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const DELETE_COMMUNITY = gql`
  mutation deleteCommunity($id: ID!) {
    deleteCommunity(id: $id) {
      id
    }
  }
`;

export const COMMUNITY_QUERY_PRIVATE = gql`
  query CommunityPrivate($id: ID!) {
    Community(id: $id) {
      id
      featuredItems {
        id
        title
        description
        createdAt
        image
        document
        video
        link
        isPrivate
      }
    }
  }
`;
